// extracted by mini-css-extract-plugin
export var align_center = "contentBlock-module--align_center--994b3";
export var align_left = "contentBlock-module--align_left--dccd8";
export var align_right = "contentBlock-module--align_right--85627";
export var center = "contentBlock-module--center--aedd3";
export var contentBlock = "contentBlock-module--contentBlock--097eb";
export var contentBlockItem = "contentBlock-module--contentBlockItem--e334e";
export var creativity = "contentBlock-module--creativity--95361";
export var emotion = "contentBlock-module--emotion--bce8e";
export var font_main = "contentBlock-module--font_main--98e56";
export var font_special = "contentBlock-module--font_special--d8b83";
export var font_special10 = "contentBlock-module--font_special10--b885a";
export var fullwidth = "contentBlock-module--fullwidth--3c53b";
export var heading = "contentBlock-module--heading--c8392";
export var intro = "contentBlock-module--intro--5bd90";
export var reason = "contentBlock-module--reason--6e245";
export var reverse = "contentBlock-module--reverse--367b4";
export var size_l = "contentBlock-module--size_l--17633";
export var size_m = "contentBlock-module--size_m--cf3fb";
export var size_s = "contentBlock-module--size_s--ebe7a";
export var size_xl = "contentBlock-module--size_xl--fc5c9";
export var size_xs = "contentBlock-module--size_xs--7c097";
export var size_xxl = "contentBlock-module--size_xxl--5106e";
export var size_xxxl = "contentBlock-module--size_xxxl--d4d64";
export var spacing_0 = "contentBlock-module--spacing_0--51647";
export var spacing_l = "contentBlock-module--spacing_l--30d1e";
export var spacing_m = "contentBlock-module--spacing_m--0b3d0";
export var spacing_s = "contentBlock-module--spacing_s--d524e";
export var spacing_xl = "contentBlock-module--spacing_xl--50cdb";
export var spacing_xxl = "contentBlock-module--spacing_xxl--58422";
export var spacing_xxxl = "contentBlock-module--spacing_xxxl--2ad67";
export var team = "contentBlock-module--team--b6989";
export var title = "contentBlock-module--title--9fa1e";
export var uppercase = "contentBlock-module--uppercase--93686";
export var weight_400 = "contentBlock-module--weight_400--6b13d";
export var weight_500 = "contentBlock-module--weight_500--828e7";
export var weight_600 = "contentBlock-module--weight_600--fb576";
export var weight_700 = "contentBlock-module--weight_700--18539";
export var weight_800 = "contentBlock-module--weight_800--c6aa5";
export var weight_900 = "contentBlock-module--weight_900--88379";