import React, { useRef, useEffect, useState } from 'react';
import * as styles from './hero.module.scss';

import { GatsbyImage } from "gatsby-plugin-image"

import { motion } from 'framer-motion';

export const Hero = ({children, image, animationData, video,onVideoToggle = () =>{}, ...props}) => {
    // render html

    // { hero.heroVideo ? <video controls><source src={hero.heroVideo.url} type="video/mp4" /></video> : null }


    return (
        <div className={styles.placeholder}>
            <motion.div
                className={styles.hero}
                initial={{
                    width: animationData?.width,
                    height: animationData?.height,
                    position: 'absolute',
                    x: animationData?.x,
                    y: animationData?.y - 427

                }}
                animate={{
                    position: 'absolute',
                    width: '100%',
                    height: '100vh',
                    y: 0,
                    x: 0

                }}
                transition={{
                    duration: .5,
                    ease: [0.43,0.13,0.23,0.96],
                }}
            >
                <div className={styles.image}>
                    {image &&
                        (image.format === "mp4"? <video playsInline muted  loop autoPlay><source src={image.url}></source></video> : <GatsbyImage alt="" image={image.gatsbyImageData}></GatsbyImage> )
                    }
                </div>

                { video ? <div className={styles.overlay}><button onClick={onVideoToggle} className={styles.play}><span>Play full case</span></button></div> : null }
            </motion.div>
        </div>
    );

    // return (
    //     <div className={styles.placeholder}>
    //         <div className={styles.hero}>
    //             <div className={styles.image}>
    //                 {image.format === "mp4"? <video playsInline muted  loop autoPlay><source src={image.url}></source></video> : <GatsbyImage alt="" image={image.gatsbyImageData}></GatsbyImage> }
    //             </div>
    //             { video ? <div className={styles.overlay}><button onClick={onVideoToggle} className={styles.play}>play</button></div> : null }
    //         </div>
    //     </div>
    // );




};