import React from 'react';

import * as styles from './styles/contentBlock.module.scss';


function ContentBlockItem({children,...props}) {

    return (
        <div className={ styles.contentBlockItem}>        
            {children}
        </div>
    );
}

export default ContentBlockItem;