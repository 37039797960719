import React, { useRef, useEffect, useState } from 'react';
import * as styles from './fullwidthMedia.module.scss';

import { GatsbyImage } from "gatsby-plugin-image"


export const FullwidthMedia = ({children, image, ...props}) => {
    // render html

    // { hero.heroVideo ? <video controls><source src={hero.heroVideo.url} type="video/mp4" /></video> : null }


    return (
        <div className={styles.fullwidthMedia}>
            {image.format === "mp4"? <video controls><source src={image.url}></source></video> : <GatsbyImage alt="" image={image.gatsbyImageData}></GatsbyImage> }
        </div>
    );



};