import React from 'react';
import * as styles from './quicklinks.module.scss';
import { Heading } from '../../01_atoms/Heading/Heading';

import cx from 'classnames';
import Container from '../Container/Container';

export const Quicklinks = ({children,title,background, ...props}) => {

    // define classes
    const classname = cx(
        styles.quicklinks,
        styles[`background_${background}`]
     );

    // render html
    return (
        <div className={classname}>
            <Container>
                <div className={styles.inner}>
                    <Heading element="h3" size="m" font="main" weight="400">{title}</Heading>
                    <div className={styles.right}>
                        {children}
                    </div>
                </div>
            </Container>
        </div>
    );

};