import React from 'react';
import cx from 'classnames';

import * as styles from './styles/contentBlock.module.scss';


function ContentBlock({
    children,
    positionMediaLeft = false,
    fullwidth = false,
    blockStyling,
    ...props}) {

        

     // define classes
     const classname = cx(
        styles.contentBlock,
        { [`${styles.intro}`]: blockStyling === "intro" },
        { [`${styles.reason}`]: blockStyling === "reason" },
        { [`${styles.emotion}`]: blockStyling === "emotion" },
        { [`${styles.title}`]: blockStyling === "title" },
        { [`${styles.center}`]: blockStyling === "center" },
        { [`${styles.team}`]: blockStyling === "team" },
        { [`${styles.creativity}`]: blockStyling === "creativity" },
        { [`${styles.reverse}`]: positionMediaLeft },
        { [`${styles.fullwidth}`]: fullwidth }
     );

    
    return (
        <div className={classname}>        
            {children}
        </div>
    );
}

export default ContentBlock;