import React from "react";
import { useTranslation } from "react-i18next";
import { graphql } from 'gatsby';
import { StructuredText } from 'react-datocms';
import { GatsbyImage } from "gatsby-plugin-image"
import { HelmetDatoCms } from "gatsby-source-datocms";

import ContentBlock from "../components/02_molecules/ContentBlock/ContentBlock";
import ContentBlockItem from "../components/02_molecules/ContentBlock/ContentBlockItem";

import * as pages from '../components/03_organisms/Pages/pages.module.scss';
import { Client } from "../components/01_atoms/Client/Client";
import Section from "../components/03_organisms/Section/Section";
import { BreadcrumbItem } from "../components/02_molecules/Breadcrumb/BreadcrumbItem";
import { Breadcrumb } from "../components/02_molecules/Breadcrumb/Breadcrumb";
import { Helmet } from "react-helmet";
import { Heading } from "../components/01_atoms/Heading/Heading";
import Slider from "../components/03_organisms/Slider/Slider";
import { Hero } from "../components/03_organisms/Hero/Hero";
import { Quicklinks } from "../components/03_organisms/Quicklinks/Quicklinks";
import Button from "../components/01_atoms/Button/Button";
import { useState } from "react";
import { VideoModal } from "../components/03_organisms/VideoModal/VideoModal";

import { AnimatePresence } from 'framer-motion';
import FadeInUp from "../components/01_atoms/FadeInUp/FadeInUp";

import * as introStyles from '../components/02_molecules/Intro/intro.module.scss';
import { FullwidthMedia } from "../components/02_molecules/FullwidthMedia/FullwidthMedia";
import { HeadInject } from "../components/03_organisms/HeadInject/HeadInject";



const WorkDetail = ({data,pageContext, ...props }) => {

	// read language
	const {t} = useTranslation();

	const [videoVisible, setVideoVisible] = useState(false);

	const onVideoToggle = (e) => {
		setVideoVisible(!videoVisible);
	}

	// render html
	return (
		<>
			<HeadInject />
			<Helmet bodyAttributes={{ class: pages.detail }} />
			<HelmetDatoCms seo={data.datoCmsWork.seoMetaTags} />

			<FadeInUp delay=".25">
				<Section content={false}>
					<Breadcrumb>
						<BreadcrumbItem to="/work" title="Work" />
						<BreadcrumbItem to={`/work/${data.datoCmsWork.client.slug}`} title={data.datoCmsWork.client.name} />
						<BreadcrumbItem to={`/work/${data.datoCmsWork.client.slug}/${data.datoCmsWork.slug}`} title={data.datoCmsWork.title} active />
					</Breadcrumb>
				</Section>
			</FadeInUp>


				<Section>
					<FadeInUp delay=".5"><Heading element="h1" size="xxl" align="center" spacing="l">{data.datoCmsWork.title}</Heading></FadeInUp>
					<FadeInUp delay=".75"><Heading element="h3" size="m" uppercase font="special" align="center"><span style={{'background': '#fff','padding': '5px'}}>{data.datoCmsWork.client.name}</span></Heading></FadeInUp>
				</Section>





			<Hero
				image={data.datoCmsWork.hero[0]?.heroImage}
				video={data.datoCmsWork.hero[0]?.heroVideo ? data.datoCmsWork.hero[0].heroVideo : null }
				onVideoToggle={onVideoToggle}
				animationData={props.location.state?.animationData || null }
			/>


			<Section content={false}>
				<ContentBlock fullwidth>
					<ContentBlockItem>
						<div className={introStyles.intro}>
							<StructuredText data={data.datoCmsWork.intro}></StructuredText>
						</div>
					</ContentBlockItem>
				</ContentBlock>
			</Section>



			<Section>
				{

					data.datoCmsWork.contentBlocks.map((contentBlock,index) => {

					return (

						<div key={index}>

							{ contentBlock.internal.type === "DatoCmsTitleMediaText" &&
								<ContentBlock positionMediaLeft={contentBlock.positionMediaLeft} blockStyling={contentBlock.blockStyling} fullwidth={contentBlock.fullwidth} >
									<ContentBlockItem>
										<StructuredText data={contentBlock.title}></StructuredText>
										<StructuredText data={contentBlock.text}></StructuredText>
									</ContentBlockItem>
									<ContentBlockItem>
										{/* {contentBlock.image &&
										(contentBlock.image.format === "mp4" ?  <video controls><source src={contentBlock.image.url}></source></video> : <GatsbyImage image={contentBlock.image.gatsbyImageData} alt={contentBlock.image?.alt || ""}></GatsbyImage> )
									} */}

										{
											contentBlock.video ? 
												<video controls>
													<source src={contentBlock.video.url}></source>
												</video> :
											contentBlock.image &&
												(contentBlock.image.format === "mp4" ?  
													<video controls>
														<source src={contentBlock.image.url}></source>
													</video> : 
													<GatsbyImage image={contentBlock.image.gatsbyImageData} alt={contentBlock.image?.alt || ""}></GatsbyImage> 
												)
										}


									</ContentBlockItem>
								</ContentBlock>
							}

							{ contentBlock.internal.type === "DatoCmsMediaFullWidth" &&
								<FullwidthMedia image={contentBlock.media} />
							}


						</div>);


				})}
			</Section>

			<Quicklinks title={t('quicklinks_clientwork_title') + data.datoCmsWork.client.name} background="black">
				<Button inverted to={`/work/${data.datoCmsWork.client.slug}`}>{t('quicklinks_clientwork_cta')}</Button>
			</Quicklinks>

			<Section fullwidth background="yellow">


					<Slider title="More work">
						{data.allDatoCmsWork.group.map((client,index) => (
							<Client
								key={index}
								link={"/work/" + client.edges[0].node.client.slug}
								name={client.edges[0].node.client.name}
								image={client.edges[0].node.client.logo ? client.edges[0].node.client.logo.gatsbyImageData : null}
								primaryColor={client.edges[0].node.client.primaryColor ? client.edges[0].node.client.primaryColor.hex : null}
							/>

						))}
					</Slider>
				</Section>

			<AnimatePresence>
				{ data.datoCmsWork.hero[0]?.heroVideo && videoVisible ? <VideoModal video={data.datoCmsWork.hero[0].heroVideo} onVideoToggle={onVideoToggle} /> : null }
			</AnimatePresence>







		</>

	)
}

export default WorkDetail;

export const query = graphql`
	query WorkQuery($slug: String!) {
		datoCmsWork(slug: { eq: $slug }) {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
		title
		slug
		intro{
			value
		}
		client{
			name
			slug
		}
		hero {
			heroImage {
				gatsbyImageData
				alt
				format
				url
			}
			heroVideo {
				url
				height
				width
			}
		}
		contentBlocks {
			... on DatoCmsMediaFullWidth {
				id
				internal {
					type
				}
				media {
				  url
				  format
				  alt
				  gatsbyImageData
				}
			  }
			... on DatoCmsTitleMediaText {
						internal {
							type
						}
						blockStyling
						fullwidth
						text {
							value
						}
						title{
							value
						}
						image {
							gatsbyImageData
							alt
						}
						video {
							width
							height
							url
							alt
						  }
						positionMediaLeft
				}
			}

			
			

		}

		allDatoCmsWork {
			group(field: client___name, limit: 1) {
			  edges {
				node {
				  client {
					name
					slug
					primaryColor {
					  hex
					}
					logo {
					  gatsbyImageData
					}
				  }
				}
			  }
			}
		  }
	}
`
