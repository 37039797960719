import React  from 'react';
import * as styles from './videomodal.module.scss';
import {FocusOn} from 'react-focus-on';
import { motion } from 'framer-motion';


export const VideoModal = ({children,video,onVideoToggle = () => {}, ...props}) => {
    // render html

    return (
        <FocusOn>
            <motion.div animate={{ opacity: 1 }} initial={{opacity: 0}} transition={{ duration: .5, ease : [0.43,0.13,0.23,0.96] }} exit={{opacity: 0}} className={styles.modal}>

                <button className={styles.close} onClick={onVideoToggle}>

                </button>

                <div className={styles.videoWrapper}>
                    <motion.div className={styles.video} animate={{y: '0px', scale: 1}} initial={{ y: '50px', scale: .8}} transition={{ duration: .5, ease : [0.43,0.13,0.23,0.96] }} exit={{scale: .8}} >
                        <video controls><source src={video.url} type="video/mp4" /></video>
                    </motion.div>
                </div>

            </motion.div>
        </FocusOn>
    );


};