import React from 'react';
import { motion } from 'framer-motion';



function FadeInUp ({children, duration = .5, delay = 0 , ease = [0.43,0.13,0.23,0.96], ...props}){

    return (
        <motion.div initial={{opacity:0, y: '20px'}} animate={{opacity:1, y:0}} transition={{duration: duration, ease: ease, delay: delay }}>
           {children}
        </motion.div>
    );
        
  
    

};

export default FadeInUp;